<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-1 mt-1">
        <span class="h1 text-primary font-weight-bolder text-uppercase ml-1 my-0 mr-1">{{
            componentActivated.name
          }}</span>
        <!--        <span class="h1 text-primary font-weight-bolder text-uppercase ml-1 my-0">Gestion cabinet </span>-->
        <b-badge v-if="componentActivated.name === 'Visualiser fiche courtier' && promotionValue"
                 variant="light-success"
        >{{ promotionValue }}
        </b-badge>
      </div>
      <div v-if="componentActivated.name !== 'Courtiers'" md="6" style="text-align: right">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                  @click="visualiserFicheCourtier(null, 'Courtiers')"
        >Liste des courtiers
        </b-button>
      </div>
    </div>
    <b-card v-if="componentActivated.name === 'Courtiers'">
      <b-overlay :show="showLoading" no-wrap/>
      <!-- table -->
      <vue-good-table
          :columns="columns"
          :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
          :rows="rows"
          :rtl="direction"
          :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
          :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'lignes sélectionnées',
          clearSelectionText: 'Effacer la sélection',
          disableSelectInfo: false,
          selectAllByGroup: false
        }"
      >
        <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'statut'">
          <b-badge :variant="sourceVariant(props.row.statut)">
            {{ props.row.statut }}
          </b-badge>
        </span>
          <span v-else-if="props.column.field === 'sous_statut'">
          <b-badge :variant="sourceVariant(props.row.sous_statut)">
            {{ props.row.sous_statut }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'signateur_convention'">
            <b-badge :variant="sourceVariant(props.row.signateur_convention)">
              {{ props.row.signateur_convention }}
            </b-badge>
          </span>
          <!-- <span v-else-if="props.column.field === 'source'">
          <b-badge :variant="sourceVariant(props.row.source)">
            {{ props.row.source }}
          </b-badge>
        </span> -->
          <div style="display: flex;justify-content: space-between;" v-else-if="props.column.field == 'nom_courtier'"
                
          >
          <a @click="visualiserFicheCourtier(props.row.id, 'Visualiser fiche courtier')" style="text-decoration: underline;line-height:calc(0.3rem + 20px);text-align: left;" >{{ props.row.nom_courtier }}</a>
          <template v-if="props.row.mandat == 'signe'">
            <b-badge variant="light-success" style="padding:0.3rem;cursor:pointer;width:30px;height:100%;" v-b-tooltip.hover title="mandat signé">
              <b-img :src="require('@/assets/images/svg/signe.svg')" style="width:20px;" />
            </b-badge>
          </template>
          <template v-else>
            <b-badge variant="light-danger" style="padding:0.3rem;cursor:pointer;width:30px;height:100%;" v-b-tooltip.hover title="mandat non signé">
              <b-img :src="require('@/assets/images/svg/signe.svg')" style="width:20px;" />
            </b-badge>
          </template>
        </div>
          <span v-else-if="props.column.field == 'principalCourtier'"
                @click="visualiserFicheCourtier(props.row.id, 'Visualiser fiche courtier')"
          >
          <a style="text-decoration: underline;">{{ props.row.principalCourtier }}</a>
        </span>
        <span v-else-if="props.column.field == 'dirigeant'"
                @click="visualiserFicheCourtier(props.row.id, 'Visualiser fiche courtier')"
          >
          <a style="text-decoration: underline;">{{ props.row.dirigeant }}</a>
        </span>
          <span v-else-if="props.column.field == 'codePromo'">
         {{ props.row.codePromo ? props.row.codePromo+' - '+getValuePromoByCode(props.row.codePromo) :"" }}
        </span>
        <span v-else-if="props.column.field == 'etape_adheration_endya'">
          <b-badge :variant="sourceVariant(props.row.etape_adheration_endya)">
            {{ getValueEndyaEtapeAdheration(props.row.etape_adheration_endya) }}
          </b-badge>
          
        </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'actions'">
            <span>
              <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                <template v-slot:button-content>
                  <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                </template>
                <b-dropdown-item @click="visualiserFicheCourtier(props.row.id, 'Visualiser fiche courtier')">
                  <feather-icon class="mr-50" icon="FolderIcon"/>
                  <span>Visualiser fiche courtier</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item v-if="props.row.mandat != 'signe'" v-b-toggle.paymentSidebar @click="payementinfo(props.row)">
                  <feather-icon class="mr-50" icon="CreditCardIcon"/>
                  <span>Génération du Mandat SEPA</span>
                </b-dropdown-item> -->
                <b-dropdown-item v-b-toggle.paymentSidebar @click="payementinfo(props.row)">
                  <feather-icon class="mr-50" icon="CreditCardIcon"/>
                  <span>Envoyer un nouveau Mandat SEPA</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon class="mr-50" icon="SendIcon"/>
                  <span>Envoyer un mail</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="props.row.signateur_convention == 'Non signé' " @click="envoyeConvention(props.row.id)">
                    <feather-icon class="mr-50" icon="SendIcon"/>
                    <span>Relancer la signature de la convention de partenariat BBI/SWL</span>
                  </b-dropdown-item>
                <b-dropdown-item @click="sendPasswordReset(props.row.id)">
                  <feather-icon class="mr-50" icon="LockIcon"/>
                  <span>Réinitialiser le mot de passe</span>
                </b-dropdown-item>
                <b-dropdown-item
                    v-if="props.row.statut === 'INACTIF' && props.row.sous_statut === 'CONVENTION NON SIGNE'"
                    @click="relanceSignature(props.row.id, props.row.nom_courtier, true,props.row.email,props.row.tel,props.row.indicatif_tel)"
                >
                  <feather-icon class="mr-50" icon="SendIcon"/>
                  <span>Relancer la signature du BA et Mandat SEPA</span>
                </b-dropdown-item>
                <b-dropdown-item
                    v-if="props.row.statut === 'INACTIF' && props.row.sous_statut === 'CONVENTION NON SIGNE'"
                    @click="relanceInscription(props.row.id, props.row.nom_courtier, props.row.email, true)"
                >
                  <feather-icon class="mr-50" icon="RefreshCwIcon"/>
                  <span>Relancer l'inscription</span>
                </b-dropdown-item>
                <b-dropdown-divider/>
                <b-dropdown-item v-b-toggle.sidebar-new-code-assureur
                                 variant="warning" @click="courtierChosen=props.row.id"
                >
                  <feather-icon class="mr-50" icon="PlusCircleIcon"/>
                  <span>Nouveau code d'assureur</span>
                </b-dropdown-item>
                <b-dropdown-divider/>
                <b-dropdown-item
                    v-if="props.row.statut === 'ACTIF'"
                    variant="danger"
                    @click="resilierCourtier(props.row.id, props.row.nom_courtier)"
                >
                  <feather-icon class="mr-50" icon="XCircleIcon"/>
                  <span>Résilier à l'initiative du courtier</span>
                </b-dropdown-item>
                <b-dropdown-item variant="danger"
                                 @click="deleteCourtier(props.row.originalIndex, props.row.id)"
                >
                  <feather-icon class="mr-50" icon="TrashIcon"/>
                  <span>Supprimer</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="props.row.etape_adheration_endya == 'EN_COURS' || props.row.etape_adheration_endya == 'EN_ATTENTE'" @click="fetchConfirmiteDocuments(props.row.id)">
                  <feather-icon class="mr-50" icon="DownloadIcon"/>
                  <span>Télécharger documents ENDYA</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <b-row class="mt-2 align-items-center">
            <b-col md="6" lg="5" xxl="3">
              <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> de {{ props.total }} entrées </span>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2 align-items-center">
            <b-col>
              <b-input-group>
                <b-form-select v-model="selected" :options="options"/>
                <b-input-group-append>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                    <span>Valider </span>
                    <feather-icon class="mr-50" icon="ArrowRightIcon"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col>
              <b-pagination :per-page="pageLength" :total-rows="props.total" :value="1" align="right" first-number
                            last-number next-class="next-item" prev-class="prev-item"
                            @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </template>
        <div slot="emptystate" class="text-center align-middle">
          <span>Aucune donn&eacute;e disponible dans le tableau</span>
        </div>
      </vue-good-table>
    </b-card>
    <gestion-cabinet v-else-if="componentActivated.name === 'Visualiser fiche courtier'"
                     :component-activated="componentActivated"
                     @data-code-assureur="sendDataCodeAssureurUpdate"
                     @passing-abonnement-courtier-selected="passingAbonnementCourtierSelected"
                     :code-updated="codeUpdated"
    />
    <payement-component @set-statut-mandat-sepa="changeStatut()" v-if="courtier_payement_active" :courtier-data="courtier_payement_active"/>
    <add-new-code-assureur-component :courtier-id="courtierChosen" :data-code-assureur="dataCodeAssureurUpdate" @code-assureur-updated="codeAssureurUpdated" />
    <b-modal id="modal-relance-ba" ref="modal-relance-ba"
             :cancel-disabled="showLoadingModalBA"
             :hide-header-close="showLoadingModalBA" :ok-disabled="showLoadingModalBA"
             :title="'Relancer la signature de bulletin d\'adhésion pour ' + nameRelanceBA"
             cancel-title="Fermer"
             cancel-variant="outline-secondary"
             centered
             no-close-on-backdrop
             ok-title="Envoyer"
             @cancel="cancelModalBA"
             @ok.prevent="relanceSignature(courtierIdRelanceBA, nameRelanceBA, false,null,null,null)"
    >
      <b-overlay :show="showLoadingModalBA" no-wrap/>
      <validation-observer ref="simpleRulesRelanceBA">
        <b-form>
          <b-row>
            <!-- username -->
            <b-col cols="12">
              <b-form-group label="E-mail *" label-for="email">
                <validation-provider #default="{ errors }" name="e-mail" rules="required">
                  <b-form-input id="email" v-model="relanceComposerData.email" :state="errors.length > 0 ? false : null"
                                placeholder="E-mail"
                  />
                  <small :state="errors.length > 0 ? false : null" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- password -->
            <b-col cols="12">
              <!-- <b-form-group label="Numéro de téléphone *" label-for="phone">
                <validation-provider #default="{ errors }"
                                     :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                                     name="numéro de téléphone"
                                     rules="required"
                >
                  <cleave id="phone" v-model="relanceComposerData.tel"
                          :class="errors.length > 0 ? 'is-invalid' : ''" :options="optionsPhone.phone"
                          :raw="false" class="form-control"
                          placeholder="ex: 06 00 00 00 00"
                  />
                  <small :state="errors.length > 0 ? false : null" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <b-form-group label="TÉLÉPHONE" label-for="phone">
                    <div class="d-flex bd-highlight">
                        <div class="w-25 bd-highlight">
                              <v-select v-model="relanceComposerData.indicatif" :close-on-select="true" :clearable="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif"
                                input-id="indicatif" label="indicatif" class="indicatif-chooser w-100"
                            >
                                <template #option="{ indicatif,code }">
                                  <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                                </template>
                                <template #selected-option="{ indicatif }">
                                  <span> {{ indicatif }}</span>
                                </template>
                                <div slot="no-options">Aucune indicatif disponible.</div>
                            </v-select>
                        </div>
                    <div class="w-75 bd-highlight">
                      <validation-provider #default="{ errors }" name="Numéro de téléphone *" :rules="{regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/}">                              
                        <b-form-input id="phone"  style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;" v-mask="'## ## ## ## ##'" v-model="relanceComposerData.tel" placeholder="ex: 06 00 00 00 00" type="tel" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </div>
            </b-form-group>

            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal id="modal-relance-rg" ref="modal-relance-rg"
             :cancel-disabled="showLoadingModalRG"
             :hide-header-close="showLoadingModalRG" :ok-disabled="showLoadingModalRG"
             :title="'Réinitialiser l\'inscription pour ' + nameRelanceRG"
             cancel-title="Fermer"
             cancel-variant="outline-secondary"
             centered
             no-close-on-backdrop
             ok-title="Confirmer"
             @cancel="cancelModalRG"
             @ok.prevent="relanceInscription(courtierIdRelanceRG, nameRelanceRG, relanceInscriptionData.email, false)"
    >
      <b-overlay :show="showLoadingModalRG" no-wrap/>
      <validation-observer ref="simpleRulesRelanceRG">
        <b-form>
          <b-row>
            <!-- username -->
            <b-col cols="12">
              <b-form-group label="E-mail *" label-for="email">
                <validation-provider #default="{ errors }" name="e-mail" rules="required">
                  <b-form-input id="email" v-model="relanceInscriptionData.email"
                                :state="errors.length > 0 ? false : null"
                                placeholder="E-mail"
                  />
                  <small :state="errors.length > 0 ? false : null" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
      <b-overlay :show="busy" no-wrap>
        <template #overlay>
          <div v-if="processing" class="text-center p-4 bg-primary text-light rounded">
            <feather-icon class="mb-2" icon="DownloadCloudIcon" size="48"/>
            <div class="mb-3">Processing... {{ counter * 5 }}%</div>
            <b-progress
              min="1"
              max="20"
              :value="counter"
              variant="light"
              height="3px"
              class="mx-n4 rounded-0"
            ></b-progress>
          </div>
          </template>
        </b-overlay>

  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  VBToggle,
  BImg,
  VBTooltip,
  BProgress
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import gestionCabinet from './gestion-cabinet/GestionCabinet.vue'
import payementComponent from './gestion-cabinet/PayementComponenet.vue'
import addNewCodeAssureurComponent from './code-assureur/AddNewCodeAssureur.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    // VGT
    VueGoodTable,
    Cleave,
    vSelect,

    // VBT
    BFormInput,
    BForm,
    BFormGroup,
    BModal,
    BBadge,
    BPagination,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BDropdownDivider,
    BImg,
    VBTooltip,
    BProgress,
    // UI
    gestionCabinet,
    payementComponent,
    addNewCodeAssureurComponent,

    // validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  created(){
    this.getPays()
  },
  data() {
    return {
      busy: false,
        processing: false,
        counter: 1,
        interval: null,
      codeUpdated: false,
      indicatif: [],
      courtier_payement_active: null,
      courtierChosen: null,
      optionsPhone: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        }
      },
      required,
      promotionValue: '',
      componentActivated: {
        name: 'Courtiers',
        courtierId: null
      },
      pageLength: 5,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      dir: false,
      showLoadingModalBA: false,
      showLoadingModalRG: false,
      selected: null,
      columns: [
        {
          field: 'dirigeant',
          label: 'Dirigeant',
          sortable: false,
          filterable: true,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'Dirigeant'
          }
        },
        {
          field: 'principalCourtier',
          label: 'Administrateur',
          sortable: false,
          filterable: true,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'Administrateur'
          }
        },

        {
          field: 'numero_orias',
          label: 'N° ORIAS',
          sortable: false,
          filterable: true,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'N° ORIAS'
          }
        },
        {
          field: 'nom_courtier',
          label: 'Dénomination commerciale',
          sortable: false,
          filterable: true,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'Dénomination commerciale'
          }
        },
        // { field: 'gestionnaire', label: 'Gestionnaire', sortable: false, filterable: true, thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6', filterOptions: { enabled: true, placeholder: 'Gestionnaire' } },
        {
          field: 'date_entree',
          label: 'Entrée',
          type: 'date',
          filterable: true,
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'Période entrer',
            filterFn: this.dateRangeFilter
          }
        },
        {
          field: 'date_sortie',
          label: 'Sortie',
          type: 'date',
          filterable: true,
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'Période sortir',
            filterFn: this.dateRangeFilter
          }
        },
        // {
        //   field: 'source',
        //   label: 'Externe/Interne',
        //   sortable: false,
        //   filterable: true,
        //   thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-1 align-middle h6',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Tous',
        //     filterDropdownItems: ['Interne', 'Externe']
        //   }
        // },
        {
          field: 'statut',
          label: 'Statut',
          sortable: false,
          filterable: true,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'Tous',
            filterDropdownItems: ['ACTIF', 'INACTIF']
          }
        },
        {
          field: 'sous_statut',
          label: 'Sous statut',
          sortable: false,
          filterable: true,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'Tous',
            filterDropdownItems: ['CONVENTION NON SIGNE', 'ATTENTE VALIDATION', 'SIGNATURE PROTOCOLE', 'OPERATIONNEL', 'DEFAUT PAIEMENT', 'DEFAUT FRAUDE', 'DEFAUT DESINSCRIPTION', 'RESILIE COURTIER']
          }
        },
        {
          field: 'signateur_convention',
          label: 'Convention SwissLife',
          sortable: false,
          filterable: true,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'Tous',
            filterDropdownItems: ['Non signé', 'Non codé', 'Signé']
          }
        },
        {
          field: 'formule',
          label: 'Formule',
          sortable: false,
          filterable: true,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'Forme'
          }
        },
        {
          field: 'codePromo',
          label: 'Code promo',
          sortable: false,
          filterable: true,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'Code promo'
          }
        },
        {
          field: 'etape_adheration_endya',
          label: 'Endya',
          sortable: false,
          filterable: true,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          filterOptions: {
            enabled: true,
            placeholder: 'Tous',
            filterDropdownItems: [{value:'NON_INITIE',text:'Non initié'}, {value:'EN_ATTENTE',text:'En attente'},{value:'EN_COURS',text:'En cours'}]
          }
        },
        {
          field: 'actions',
          label: 'Actions',
          sortable: false,
          filterable: false,
          thClass: 'border-0 text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      options: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'autre',
          text: ' --- '
        }
      ],
      rows: [],
      searchTerm: '',
      showLoading: false,
      courtierIdRelanceBA: null,
      nameRelanceBA: null,
      courtierIdRelanceRG: null,
      nameRelanceRG: null,
      relanceComposerData: {
        email: null,
        tel: null,
        indicatif: null
      },
      relanceInscriptionData: {
        email: null
      },
      dataCodeAssureurUpdate: {
        id: null,
        courtierId: null,
        assureurId: null,
        produitId: null,
        codeDirect: null,
        codeLpc: null,
        statut: 'Ouvert',
        typeCode: 'DIRECT',
        statutCode: 'NOUVEAU',
        directLpc: 'DIRECT',
        identifiantWS: null,
        passwordWS: null,
      }
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.fetchCourtierList()
  },
  beforeDestroy() {
      this.clearInterval()
    },
  methods: {
    envoyeConvention(id) {
      console.log(id)
      this.showLoading = true
      this.$http.post('courtier/envoyeConvention', { 'id': id }).then((res) => {
        if (res.data.success) {
          if (res.data.data.url) {
            this.fetchCourtierList()
            this.messageToast(res.data.message, 'Succès', 'success')
            this.showLoading = false

          }
          else {
            this.showLoading = false
            this.messageToast(res.data.data.erreur, 'Erreur', 'danger')
          }
        }
      })
        .catch(err => {
          this.showLoading = false
          this.messageToast('Erreur Traitement n\'est pas bien passe ', 'Erreur', 'danger')
          console.error(err)
        })

    },
    fetchConfirmiteDocuments(id){
      this.busy=true
      this.counter = 1
        this.processing = true
        this.$http
          .get(`/association_autoregulation/fetch-confirmite-documents-courtier/${id}`)
          .then(res => {
            if (res.data.success) {
              console.log(res.data.data)
              if(res.data.data.length > 0){
                res.data.data.forEach(doc => {
                  this.downloadDocument(doc)
                });
                console.log(this.rows)
                let index = this.rows.findIndex((c)=>c.id == id);
                this.rows[index].etape_adheration_endya = 'EN_COURS';
                
              }
            }
          })
          .catch(err => {
            this.messageToast('Erreur dans la récupération les documents demandée.', 'Erreur', 'danger')
            console.error(err)
          })
        this.clearInterval()
        this.interval = setInterval(() => {
          if (this.counter < 20) {
            this.counter = this.counter + 1
          } else {
            this.clearInterval()
            this.$nextTick(() => {
              this.busy = this.processing = false
            })
          }
        }, 350)
      
    },
    downloadDocument(doc){
      this.$http.post('document/download_document_conformite_for_adheration_associaiton',doc).then((res)=>{
        console.log(res.data);
        // let blob = new Blob([res.data]);
        let link = document.createElement('a');
        link.href = res.data;
        link.download = doc.Name
        link.click()
      }).catch(err => {
        console.error(err)
      })
    },
    clearInterval() {
        if (this.interval) {
          clearInterval(this.interval)
          this.interval = null
        }
      },
      
     
    codeAssureurUpdated() {
      this.codeUpdated = true
      setTimeout(() => {
        this.codeUpdated = false
      }, 1000);
    },
    sendDataCodeAssureurUpdate(data) {
      this.mapDataCodeAssureur(data)
      this.$root.$emit('bv::toggle::collapse', 'sidebar-new-code-assureur')
    },
    mapDataCodeAssureur(data) {
      this.dataCodeAssureurUpdate.id = data.id
      this.dataCodeAssureurUpdate.courtierId = data.courtier_fk
      this.dataCodeAssureurUpdate.assureurId = data.assureur_id
      this.dataCodeAssureurUpdate.codeDirect = data.code_direct
      this.dataCodeAssureurUpdate.codeLpc = data.code_lpc
      this.dataCodeAssureurUpdate.statut = data.statut
      this.dataCodeAssureurUpdate.typeCode = data.type_code ? data.type_code.toUpperCase() : null
      this.dataCodeAssureurUpdate.statutCode = data.statut_code
      this.dataCodeAssureurUpdate.directLpc = data.direct_lpc
      if(data.courtier_user_assureurs.length > 0) {
        this.dataCodeAssureurUpdate.identifiantWS = data.courtier_user_assureurs[0].identifiant_ws
        this.dataCodeAssureurUpdate.passwordWS = data.courtier_user_assureurs[0].password_ws
      }
      if(data.produits.length > 0) {
        this.dataCodeAssureurUpdate.produitId = data.produits.map((item) => {
          return {
            "value": item.id,
            "text":item.produit_nom
          }
        })
      }
    },
    changeStatut()
    {
      this.fetchCourtierList()
    },
    getPays(){
      this.$http.get(`/getPays`)
      .then((r) => {
        let france = r.data.find(item => item.indicatif == "+33")
        r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        this.indicatif = r.data
      })
      .catch((e)=>{

        console.log(e);

      })
    },
    passingAbonnementCourtierSelected(abonnement) {
      if (abonnement && abonnement.promotion) {
        this.promotionValue = this.getValuePromoByCode(abonnement.promotion.code)
      } else {
        this.promotionValue = ''
      }
    },
    getValuePromoByCode(code) {
      switch (code) {
        case 'BUBBLE2M':
          return '2 mois gratuits'
        case 'BUBBLE2022':
        case 'BUBBLEWDM' :
        case 'BUBBLEC1C' :
        case 'BubbleParrainage6M' :
        case 'BUBBLE6M':  
          return '6 mois gratuits'
        case 'BUBBLE1AJDC':
        case 'BUBBLEWDM12':
          return '12 mois gratuits'
        default:
          return ''
      }
    },
    getValueEndyaEtapeAdheration(etape) {
      switch (etape) {
        case 'EN_ATTENTE':
          return 'En attente'
        case 'EN_COURS' :
          return 'En cours'
        case 'NON_INITIE' :
          return 'Non initie'
        default:
          return 'Non initie'
      }
    },
    visualiserFicheCourtier(courtierId = null, component) {
      this.componentActivated.name = component
      this.componentActivated.courtierId = courtierId
    },
    payementinfo(courtier) {
      this.courtier_payement_active = courtier
    },
    fetchCourtierList() {
      this.showLoading = true
      this.$http
          .get(`/courtier/fetchAllCourtiers/${this.currentUser.courtier_user[0].user_id}`)
          .then(res => {
            if (res.data.success) {
              this.rows = res.data.data
              this.showLoading = false
              // this.messageToast(res.data.message, 'Succès', 'success', 'InfoIcon')
            }
          })
          .catch(err => {
            this.showLoading = false
            this.rows = []
            this.messageToast(err.response.data.errors[0], 'Erreur', 'danger')
            console.error(err)
          })
    },
    deleteCourtier(index, courtierId) {
      this.$swal({
        title: '',
        text: 'Etes-vous sûr de vouloir supprimer le courtier ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(result => {
            if (result.value) {
              this.showLoading = true
              this.$http
                  .delete(`/courtier/deleteCourtier/${courtierId}`)
                  .then(res => {
                    if (res.data.success) {
                      this.rows.splice(index, 1)
                      this.messageToast(res.data.message, 'Succès', 'success')
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'danger')
                    console.error(err)
                  })
            }
          })
    },
    sendPasswordReset(courtierId) {
      this.$swal({
        title: '',
        text: 'Etes-vous sûr de vouloir envoyer un e-mail de réinitialisation du mot de passe ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(result => {
            if (result.value) {
              this.showLoading = true
              this.$http
                  .put(`/collaborateur/SendIdentifiantsTemporaire/${courtierId}`)
                  .then(res => {
                    if (res.data.success) {
                      this.messageToast(res.data.message, 'Succès', 'success')
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'danger')
                    console.error(err)
                  })
            }
          })
    },
    cancelModalBA() {
      this.relanceComposerData.email = null
      this.relanceComposerData.tel = null
      this.relanceComposerData.indicatif = null
      this.$refs['modal-relance-ba'].hide()
    },
    cancelModalRG() {
      this.relanceInscriptionData.email = null
      this.$refs['modal-relance-rg'].hide()
    },
    resilierCourtier(courtierId, courtierName) {
      this.$swal({
        title: '',
        text: `Souhaitez-vous résilier le contrat à l’initiative du courtier ${courtierName}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.showLoading = true
              this.$http
                  .delete(`/courtier/resilierCourtier/${courtierId}`)
                  .then(res => {
                    if (res.data.success) {
                      this.messageToast(res.data.message, 'Succès', 'success', 'BellIcon')
                      this.showLoading = false
                      this.fetchCourtierList()
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'danger', 'BellIcon')
                    console.error(err)
                  })
            }
          })
    },
    relanceSignature(courtierId, name, showModal,email,tel,indicatif) {
      if (showModal) {
        this.courtierIdRelanceBA = courtierId
        this.nameRelanceBA = name
        this.relanceComposerData.email = email
        this.relanceComposerData.tel = tel
        this.relanceComposerData.indicatif = indicatif
        this.$refs['modal-relance-ba'].show()
      } else {
        this.$refs.simpleRulesRelanceBA.validate()
            .then(success => {
              if (success) {
                this.showLoadingModalBA = true
                this.$http
                    .put(`/courtier/relanceSignatureBulletinAdhesion/${this.courtierIdRelanceBA}`, {
                      email: this.relanceComposerData.email,
                      tel: this.relanceComposerData.tel,
                      indicatif: this.relanceComposerData.indicatif
                    })
                    .then(res => {
                      if (res.data.success) {
                        this.messageToast(res.data.message, 'Succès', 'success', 'BellIcon')
                        this.showLoadingModalBA = false
                        this.relanceComposerData.email = null
                        this.relanceComposerData.tel = null
                        this.relanceComposerData.indicatif = null
                        this.$refs['modal-relance-ba'].hide()
                      }
                    })
                    .catch(err => {
                      this.showLoadingModalBA = false
                      this.messageToast(err.response.data.errors[0], 'Erreur', 'danger', 'BellIcon')
                      console.error(err)
                    })
              }
            })
      }
    },
    relanceInscription(courtierId, name, email, showModal) {
      if (showModal) {
        this.courtierIdRelanceRG = courtierId
        this.nameRelanceRG = name
        this.relanceInscriptionData.email = email
        this.$refs['modal-relance-rg'].show()
      } else {
        this.$refs.simpleRulesRelanceRG.validate()
            .then(success => {
              if (success) {
                this.showLoadingModalRG = true
                this.$http
                    .put(`/courtier/relanceInscription/${this.courtierIdRelanceRG}`, this.relanceInscriptionData)
                    .then(res => {
                      if (res.data.success) {
                        this.messageToast(res.data.message, 'Succès', 'success', 'BellIcon')
                        this.showLoadingModalRG = false
                        this.cancelModalRG()
                        this.fetchCourtierList()
                      }
                    })
                    .catch(err => {
                      this.showLoadingModalRG = false
                      this.messageToast(err.response.data.errors[0], 'Erreur', 'danger', 'BellIcon')
                      console.error(err)
                    })
              }
            })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
/*css indicatif */

.indicatif-chooser .vs__dropdown-toggle {

border-radius: 0.357rem 0px 0px 0.357rem;

padding-bottom: 4.5px;

}

.v-select.indicatif-chooser.vs--open.vs--single.vs--searchable

{

min-width: 20%;

width:20%;

}

input#indicatif.vs__search{

padding: 0px;

}

.vs__selected-options{

height: 31px;

}
.vs--single .vs__dropdown-toggle {
  padding-left: 0px;}
/* css indicatif */
</style>
